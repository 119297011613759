<template>
	<div class="team-members">
		<div class="row">
			<div class="col-12">
				<h6 class="oml-h6Title">{{ $t('FacebookToken') }} <i class="fa fa-spinner fa-spin" style="font-size: 18px;" v-if="globalLoading"></i></h6>
				
				<div class="p-desc mt-3">
					<p>{{ $t('FacebookTokenDesc') }}</p>
				</div>
			</div>
		</div>
		
		<div class="row">
			
			<div class="event_processing" v-show="Loading">
				<!--i class="fa fa-spinner fa-spin fa-3x fa-fw"></i-->
				<img src="/img/ajax-loader.gif" />
			</div>
			
			<div class="col-12 mx-auto">
				<div class="heading-invit-membre pt-0">
					<div class="row">
						<!--div class="col-12">
							<h4>{{ $t('FacebookToken') }} <i class="fa fa-spinner fa-spin" style="font-size: 18px;" v-if="globalLoading"></i></h4>
						</div-->
						<div class="col-12">
							<div class="table-grid fb-table">
								<table class="table-md">
									<thead>
										<tr>
											<th v-for="(item, key) in columns" v-bind:key="key">
											<!--th v-for="(item, key) in columns" @click="sortBy(item.column)" :class="{ active: sortKey == item.column }" v-bind:key="key"-->
												<div>
													{{ $t(item.title) }}
													<div class="fa fa-sort-amount-asc" :class="item.title != '' ? 'asc' : 'dsc'"></div>
													<!--div class="arrow" :class="sortOrders[item.column] > 0 ? 'asc' : 'dsc'"></div-->
												</div>
											</th>
										</tr>
									</thead>
									<tbody>
										<tr v-for="(item, key) in facebookPages" v-bind:key="key">
											<td>{{ item.page_name }}</td>
											<td :title="item.token">{{ item.token | maxLength }}</td>
											<td>{{ item.facebook_user_name }}</td>
											<td>
												<span class="badge badge-danger" v-if="item.token_expired == 1">{{ $t('Expired') }}</span>
												<span class="badge badge-success" v-if="item.token_expired == 0">{{ $t('Valid') }}</span>
											</td>
											<td>
												{{ item.created_at | moment('timezone', workspaceTimezone, workspaceDateFormat) }}
											</td>
											<td><!--  class="text-center" -->
											
												<b-dropdown size="sm"  variant="link" toggle-class="text-decoration-none" right no-caret>
													<template v-slot:button-content>
														<i class="fa fa-ellipsis-v dropdown-icon"></i>
													</template>
													<b-dropdown-item @click="GenerateNew(item)"><i class="fa fa-cogs pr-1"></i> {{ $t('Regenerate') }}</b-dropdown-item>
												</b-dropdown>
												
											</td>
										</tr>
										
										<tr v-if="facebookPages.length == 0">
											<td :colspan="columns.length"><center>{{ $t('NoDataToDisplay') }} !</center></td>
										</tr>
										
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
				
			</div>
		</div>
		
    </div>
</template>

<script>
	export default {
		name: 'team',
		data() {
			return {
				
				columns: [
					{ title: 'PageName'   , column: 'page_name'  	},
					{ title: 'Token'  	   , column: 'token'	  	},
					{ title: 'GeneratedBy' , column: 'facebook_user_name'	},
					{ title: 'Status' 	   , column: 'status'	},
					{ title: 'CreatedAt'   , column: 'created_at'  	},
					{ title: 'Actions'	   , column: 'actions'	  	},
				],
				
				facebookPages : [],
				globalLoading : false,
				AuthResponse  : {},
				Loading		  : false,
			}
		},
		methods: {
			fbPostRequest(page_id) {
				let that = this
				that.Loading = true
				let url   = '/regenerate_token/' + page_id
				let method= 'POST'
				let data  = that.AuthResponse
				that.$store.dispatch('apiRequest', { url, method, data } ).then((response) => {
					if( response.data.success ) {
						that.GetToken()
					}
					that.notifyMe(response.data.message, 'success')
					that.Loading = false
				}).catch(( {response} ) => {
					if( typeof(response) != 'undefined' ) {
						that.notifyMe(response.data.message, 'warn')
					}
					that.Loading = false
				})
				
			},
			GenerateNew(item) {
				let that = this
				window.FB.getLoginStatus(function(response) {
					if( response.authResponse == null ) {
						window.FB.login(function(response) {
							if (response.authResponse) {
								let params = {
									limit: 200,
									access_token: response.authResponse.accessToken
								};
								window.FB.api('/me', 'GET', params, (user) => {
									if( typeof(user.name) != 'undefined' && user.name != null ) {
										Object.keys(response.authResponse).forEach( function(el) {
											that.AuthResponse[el] = response.authResponse[el]
										} )
										that.AuthResponse['name'] = user.name
										that.AuthResponse['facebook_page_id'] = item.id
										that.fbPostRequest(item.id)
									} else {
										that.notifyMe('Please try again !', 'warn')
									}
								})
							}
						}, { /* scope: 'email', */ return_scopes: true, enable_profile_selector: true });
					} else {
						let params = {
							limit: 200,
							access_token: response.authResponse.accessToken
						};
						window.FB.api('/me', 'GET', params, (user) => {
							if( typeof(user.name) != 'undefined' && user.name != null ) {
								Object.keys(response.authResponse).forEach( function(el) {
									that.AuthResponse[el] = response.authResponse[el]
								} )
								that.AuthResponse['name'] = user.name
								that.AuthResponse['facebook_page_id'] = item.id
								that.fbPostRequest(item.id)
							} else {
								that.notifyMe('Please try again !', 'warn')
							}
						})
					}
				})
				
			},
			notifyMe(message, variant) {
				this.$notify({
					group	: 'foo',
					// title: 'Important message',
					text	: message,
					type	: variant,
					// duration: 500000,
					// speed	: 1000
				});
			},
			
			GetToken() {
				let that = this
				
				let url   = '/tokens'
				let method= 'GET'
				let data  = {}
				that.globalLoading = true
				that.$store.dispatch('apiRequest', { url, method, data } ).then((response) => {
					
					that.facebookPages = []
					let facebookPages  = response.data.facebookPages
					facebookPages.forEach( function(item) {
						that.facebookPages.push({
							id					: item.id,
							token				: item.facebook_page_token,
							token_expired		: item.token_expired,
							facebook_user_name	: item.facebook_user.facebook_name,
							page_name			: item.page_name,
							created_at			: item.created_at
						})
					} );
					
					that.globalLoading = false
				}).catch(( {response} ) => {
					that.notifyMe(response.data.message, 'warn')
					that.globalLoading = false
				})
				
			},
			
		},
		
		created() {
			
			(function(d, s, id){
			var js, fjs = d.getElementsByTagName(s)[0];
			if (d.getElementById(id)) {return;}
			js = d.createElement(s); js.id = id;
			js.src = "//connect.facebook.net/en_US/sdk.js";
			fjs.parentNode.insertBefore(js, fjs);
			}(document, 'script', 'facebook-jssdk'));
			
			window.fbAsyncInit = function() {
				window.FB.init({
					appId		: '155127881962747',
					// status     : true,
					xfbml   	: true,
					version		: 'v3.1'
				});
				
				//This function should be here, inside window.fbAsyncInit
				// window.FB.getLoginStatus(function(response) {
					// console.log(response);
				// });
			};
			
		},
		
		mounted() {
			this.GetToken()
			this.$store.state.selectedPage = 'fbtoken'
		},
		
		filters: {
			
			maxLength: function (str) {
				if( str != null && str != '' ) {
					return str.length > 19 ? str.substring(0, 19) + "..." : str
				} else {
					return ''
				}
			}
		},
		
	}
</script>

<style lang="scss">
	
	.table-grid {
		thead th {
			font-weight: normal !important;
			font-size: 14px;
		}
	}
	
	.custom-control-inline {
		width: 8em;
	}
	
	[role-tooltip] {
		position:relative;
	}
	[role-tooltip]:before {
		
		position: absolute;
		left: -52px;
		top: -52px;
		font-size: 12px;
		height: 4em;
		background-color: #171717;
		color: #ffffff;
		border-radius: 5px;
		padding: 5px 8px;
		content: attr(content);
		display: none;
		transition: .25s ease-in-out;
		font-weight: 600;
		width: 30em;
		
	}
	[role-tooltip]:after {
		position: absolute;
		right: 30%;
		top: -4px;
		border-top: 7px solid #171717;
		border-left: 7px solid transparent;
		border-right: 7px solid transparent;
		content: "";
		display: none;
		transition:.25s ease-in-out;
	}
	[role-tooltip]:hover:after, [role-tooltip]:hover:before {
		display: block;
		transition:.25s ease-in-out;
	}
	
	.fb-table{
		thead{
			th{
				& > div{
					color: #8c8c8c;
					font-weight: normal;
					font-size: 14px;
					display:flex;
					align-items:center;
					justify-content:space-between;
					
					.fa{
						opacity: 0.66;
					}
				}
			}
		}
		
	}
	
</style>
